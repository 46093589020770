import { createTheme } from "@mui/material/styles"

//For a full list of values used by MUI, visit this link:
//https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette
const colors = {
	type: "light",
	primary: {
		main: "rgb(0, 179, 197)",
		mainROne: "rgb(34, 150, 206)",
		contrastText: "rgb(255, 255, 255)",
		transparency50: "rgba(0, 179, 197, 0.5)",
		transparency30: "rgba(0, 179, 197, 0.3)",
		transparency20: "rgba(0, 179, 197, 0.2)",
		transparency10: "rgba(0, 179, 197, 0.1)",
		accent: "#02B3C5",
		rOneBG: "#e0e0e0"
	},
	secondary: {
		main: "rgb(0, 53, 245)",
	},
	tertiary: {
		main: "#FFA500", // new color
	},
	alertRed: {
		main: "rgba(211,47,47, .45)",
		highlight: "rgba(211,47,47, .2)",
		mainTransparency100: "rgb(211,47,47)",
		ROne: "rgb(216, 106, 106)",
	},
	panels: {
		ROneBg: "rgb(224, 224, 224)",
		main: "rgb(241,241,241)",
		multiSelect: "rgb(49, 81, 97)",
		inputGrey: "rgba(0, 0, 0, 0.87)"
	},
	notes: {
		background: "rgb(255, 255, 255)",
		crumbsBackground: "rgb(241, 241, 241)",
		dark: "rgb(224,224,224)",
		highlightBg: "rgb(250,250,250)",
		hoverBg: "#eee",
		selected: "rgba(49, 191, 204, 0.25)",
		selectedHighlightBg: "rgba(49, 191, 204, 0.15)",
		selectedGreen: "lightgreen",
		moveNoteIndicator: "#fce1bd",
		moveNoteIndicatorAccent:"#F6B35A",
		toggleChildrenIcon: "rgba(153, 153, 153, 0.7)"
	},
	unassignedArticle: {
		main:"rgba(169, 108, 108, 0.23)",
		darker: "rgb(169, 108, 108)"
	},
	excludedArticle: {
		main: "rgb(170,170,170)",
		dark: "rgb(119, 119, 119)",
		light: "rgb(204,204,204)",
		highlightBg: "#e8e8e8",
	},
	dragBar: {
		main: "lightblue",
		importedLight: "rgba(80, 183, 113, 0.3)",
		importedHover: "rgba(80, 183, 113, 0.6)",
		importedDark: "rgb(80, 183, 113)",
		pdfIcon: "#f58e8e", //"#487b55"
		pdfHover: "#fd5a5a", //"#1a7932"
		excluded: "rgb(119,119,119)",
		excludedHover: "rgb(95,95,95)",
		excludeROne: "rgba(197, 118, 118, 0.85)",
		excludeROneHover: "rgba(197, 118, 118, 1)",
		importROne: "rgba(34, 150, 206, 0.85)",
		importROneHover: "rgba(34, 150, 206,1)",
	},
	RBOneFont: {
		main: "rgb(85, 85, 85)",
		authors: "rgb(117, 139, 150)",
		otherInfo: "rgb(136, 136, 136)",
	},
	action: {
		hoverOpacity: 0.2,
		active: "rgba(0, 0, 0, 0.54)"
	},
	homepageBlack: {
		main: "#263130",
		color: "#ffffff",
	}
}

let theme = createTheme({
	palette: {
		...colors,
		background: {
			paper: "rgb(255, 255, 255)", //GLOBAL DECLARATION FOR ALL COMPONENTS THAT USE Mui-paper AS THEIR ROOT
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
                @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
            `,
		},
		MuiTextField: {
			styleOverrides: {
				root: {
				// this is styles for the new variants
					"&.tunedTextField": {
						"& label": {
							fontStyle: "italic",
							width: "100%"
						},
					},
					"&.keyTextField": {
						"& label": {
							
						},
					},
				}
			}
		},
		MuiIconButton: {
			styleOverrides: {
				/* NOTE: keep this documentation for future reference
                 *
                 * below, we've overridden the root style for <Icon-Button> in two different ways:
                 * In currently used one, we're using a basic object, which functions like an sx:{} object
                 * In the commented out one, we're able to access the component's current state by passing
                    in a callback function which passes in the arguments "ownerState" and "theme".
                    ownerState includes every prop passed into the component, while theme includes
                    the theme defined in this script AND the default style made by MUI.

                 * We can examine these args using the console statement on-hover. This is INSANELY powerful,
                    but currently doesn't have a good use-case. However, if icon colors become an issue
                    in the future, we could programmatically look at the value passed into the "color" or
                    "variant" props, modify the alpha value, and set it to the backgroundColor value.
                 */
				// root: ({ownerState, theme}) => ({
				//     borderRadius: 0,
				//     height: "100%",
				//     // "&:hover": {
				//     //     backgroundColor: console.log(ownerState, `\n`, theme) && (ownerState.color || "green")
				//     // }
				// })
				root: {
					padding: 0,
					flexGrow: 1,
					borderRadius: 0,
					height: "100%",
				},
			},
			variants: [
				{
					props: { variant: "grab-bar-exclude" },
					style: {
						backgroundColor: colors.dragBar.excludeROne,
						color: colors.panels.main,
						transition:
							"background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
						"&:hover": {
							backgroundColor: colors.dragBar.excludeROneHover,
						},
						width: "40px",
					},
				},
				{
					props: { variant: "grab-bar-excluded" },
					style: {
						backgroundColor: colors.dragBar.excluded,
						color: "rgba(0,0,0, 0.25)",
						transition:
							"background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
						"&:hover": {
							backgroundColor: colors.dragBar.excludedHover,
						},
						width: "40px",
					},
				},
				{
					props: { variant: "grab-bar-import" },
					style: {
						backgroundColor: colors.dragBar.importROne,
						color: colors.panels.main,
						transition:
							"background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
						"&:hover": {
							backgroundColor: colors.dragBar.importROneHover,
						},
						width: "40px"
					},
				},
				{
					props: { variant: "grab-bar-imported" },
					style: {
						backgroundColor: colors.dragBar.importedLight,
						color: colors.panels.main,
						transition:
							"background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
						"&:hover": {
							backgroundColor: colors.dragBar.importedHover,
						},
						width: "40px"
					},
				},
				{
					props: { variant: "curved-square" },
					style: {
						maxHeight: "40px",
						maxWidth: "40px",
						borderRadius: "5%",
					},
				},
				{
					props: { variant: "toggle-children" },
					style: {
						maxWidth: "25px",
						minWidth: "25px",
						maxHeight: "30px",
						rotate: "0deg",
						color: colors.notes.toggleChildrenIcon,
						backgroundColor: "transparent",
						transition:
							"background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, rotate infinite ease-out",
						"&:hover": {
							rotate: "360deg",
							backgroundColor: "rgba(0, 0, 0, 0)",
						},
					},
				},
				{
					props: { variant: "tab-close" },
					style: {
						width: "15px",
						height: "15px",
						backgroundColor: colors.action.active,
						transition:
							"background-color 250ms ease-in-out, " +
							"width 250ms ease-in-out, " +
							"height 250ms ease-in-out",
						"&:hover": {
							fontSize: "1.25rem !important",
							color: colors.alertRed.mainTransparency100,
							width: "25px",
							height: "25px",
						},
						position: "absolute",
						right: "-7px",
						top: "-7px",
						borderRadius: "100%",
						color: colors.panels.ROneBg,
						fontSize: "1rem",
					}
				}
			],
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					borderRadius: "5%",
				},
			},
		},
		MuiTooltip: {
			defaultProps: {
				enterDelay: 300,
				enterTouchDelay: 800,
				enterNextDelay: 1000,
			},
		},
		MuiCard: {
			variants: [
				{
					props: { variant: "note" },
					style: {
						zIndex: "2",
						transitionProperty: "box-shadow, z-index, background-color, border",
						transitionDuration: "125ms",
						textShadow: "rgba(0, 0, 0, 0.1) 0px 0px 1px",
						color: colors.RBOneFont.main,
						"&:hover": {
							boxShadow: "0 1px 8px rgba(153,153,153, 0.7)",
							zIndex: "3",
							backgroundColor: colors.notes.hoverBg,
						},
					},
				},
				{
					props: { variant: "excludedArticle" },
					style: {
						zIndex: "2",
						transitionProperty: "box-shadow, z-index",
						transitionDuration: "200ms",
						transitionTimingFunction: "ease-out",
						textShadow: "rgba(0, 0, 0, 0.1) 0px 0px 1px",
						color: colors.RBOneFont.main,
						backgroundColor: colors.excludedArticle.light,
						"&:hover": {
							boxShadow: "0 0px 8px rgba(0, 0, 0, 0.7)",
							zIndex: "3",
						},
					},
				},
			],
		},
		MuiTabs: {
			styleOverrides: {
				scroller: ({ ownerState }) => ({
					...(ownerState["isImportedArticlesNav"] !== undefined && {
						height: "40px",
					}),
				}),
				flexContainer: ({ ownerState }) => ({
					...(ownerState["isImportedArticlesNav"] !== undefined && {
						height: "40px",
					}),
				}),
				scrollButtons: {
					height: "100%",
					boxShadow:
						"-5px 0px 2px -2px rgba(0,0,0,0.6), 5px 0px 2px -2px rgba(0,0,0,0.6)",
					transition:
						"background-color 200ms ease-out, box-shadow 150ms ease-out",
					zIndex: 100,
					"&.Mui-disabled": {
						opacity: 0.3,
						boxShadow: "none",
						transition: "box-shadow 150ms ease-in",
					},
					"&:hover": {
						backgroundColor: "rgba(0,0,0,0.2)",
					},
				},
				root: {
					"& .MuiButtonBase-root": {
						textTransform: "none",
						fontSize: "1rem",
					},
				}
			},
			variants: [
				{
					props: {isSecondaryToolBarTabs: true,},
					style: {
						height: "40px",
						cursor: "default",
						paddingBottom: "0",
						minHeight: "32px",
						alignItems: "end",
						flex: "1 1 0",
						"& .MuiTabs-indicator": {
							height: "32px",
							boxSizing: "border-box",
							borderTopRightRadius: "4px",
							borderTopLeftRadius: "4px",
							backgroundColor: colors.primary.transparency30,
						},
						"& .MuiTabs-scrollButtons": {
							width: "20px"
						},
						"& .MuiTabs-flexContainer": {
							height: "40px",
							display: "flex",
							alignItems: "end",
						}
					}
				}
			]
		},
		MuiTab: {
			variants: [
				{
					props: {variant: "secondaryToolBarTab"},
					style: {
						height: "80%",
						minHeight: "32px",
						padding: "0px 0 0 0",
						margin: "0 4px",
						zIndex: 10,
						width: "128px",
						borderTop: "1px solid gray",
						borderLeft: "1px solid gray",
						borderRight: "1px solid gray",
						borderTopRightRadius: "4px",
						borderTopLeftRadius: "4px",
						transition: "background-color 250ms ease-in-out",
						"&:hover": { backgroundColor: colors.primary.transparency30 },
						overflow: "visible",
					}
				}
			]
		},
		ArticleSideBar: {
			variants: [
				{
					props: { variant: "imported" },
					style: {
						backgroundColor: colors.dragBar.importedLight,
						cursor: "pointer",
					},
				},
				{
					props: { isExcluded: true },
					style: {
						backgroundColor: colors.excludedArticle.dark,
						opacity: 1,
					},
				},
			],
		},
		MuiButton: {
			variants: [
				{
					props: { discreteOpen: true},
					style: {
						position: "absolute",
						bottom: 0,
						right: 0,
						margin: "0 10px 2px 0",
						textTransform: "none",
						padding: "1px 50px",
						transition: "background-color color 250ms ease-in",
						"&:hover": {
							backgroundColor: colors.primary.transparency20,
							color: colors.alertRed.mainTransparency100,
						},
						color: colors.alertRed.main,
					}
				}
			]
		},
		MuiListItem: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...ownerState["variant"] === "notetree" && {
						borderLeft: ownerState["toplevel"] === "true" ? "none" : "1px solid rgba(255,255,255,0.7)",
						display: "block",
						padding: 0
					}
				}),
			},
			variants: [
				{ props: {variant: "notetree"} },
			]
		},
		MuiList: {
			styleOverrides: {
				root: ({ownerState}) => ({
					...ownerState["variant"] === "notetree" && {
						paddingLeft: ownerState["toplevel"] === "true" ? "0" : "25px",
						paddingTop: 0,
						paddingBottom: 0,
					}
				})
			},
			variants: [
				{ props: {variant: "notetree"} },
			]
		}
	},
	typography: {
		articleTitle: {
			fontSize: ".875rem",
			lineHeight: "1.25rem",
			fontWeight: 700,
		},
		authorsList: {
			width: "90%",
			color: colors.RBOneFont.authors,
			lineHeight: "1.25rem",
			fontSize: ".875rem",
			marginBottom: "0px",
		},
		accentedText: {
			color: colors.primary.mainROne,
			display: "inline",
			fontWeight: "bold"
		},
		htmlFontSize: 16,
		fontFamily: ["Lato", "sans-serif"].join(","),
		tunedH1: {
			fontSize: "1.5rem",
			lineHeight : "1.75rem",
		},
		tunedH2: {
			fontSize: "1.25rem",
			lineHeight : "1.5rem",
		},
		tunedH3: {
			fontSize: "1em",
			lineHeight : "1.25rem",
		},
		tunedH3Bold: {
			fontSize: "1em",
			lineHeight: "1.25rem",
			fontWeight: "bold",
		},
		tunedSubtitle3: {
			fontSize: "0.75em",
			lineHeight: "1rem"
		},
		tunedSubtitle2: {
			fontSize: "1rem",
			lineHeight: "1.5rem"
		},
		tunedSubtitle1: {
			fontSize: "1rem",
			lineHeight: "1.5",
			fontWeight: "bold",
		},
		tunedSubtitle3Bold: {
			fontSize: "0.75em",
			lineHeight: "1rem",
			fontWeight: "bold"
		},
		// button: {
		// 	textTransform: "none" // GLOBAL UPPERCASE FOR ALL BUTTONS OVERRIDE!
		// }
	}
})

theme = createTheme(theme, {
	palette: {
		otherInfo: theme.palette.augmentColor({
			color: {
				main: "rgb(136, 136, 136)"
			},
			name: "otherInfo"
		}),
	}
})

export default theme
