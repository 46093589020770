import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import Dashboard from "./pages/Dashboard"
import Home from "./pages/Home"
import { ProtectedRoute } from "./components/ProtectedRoute"
import { useAuth0 } from "@auth0/auth0-react"
import axios from "axios"
import { useEffect, useState } from "react"
import NotFound from "./pages/NotFound"
import Loading from "./components/Loading"
import { useDispatch, useSelector } from "react-redux"
import { selectUser, setUserAsync } from "./reducers/userSlice"
import ErrorPage from "./pages/ErrorPage"
import RegisterForm from "./pages/registration/RegisterForm"
import PricesForm from "./pages/registration/PricesForm"
import PickPayment from "./pages/registration/Payment"
import "react-contexify/dist/ReactContexify.css"
import ManageLicenses from "./pages/ManageLicenses"
import AccountSettings from "./pages/AccountSettings"
import {setSessionTimestamp} from "./reducers/sessionTimestampSlice"
import { selectWorkspaceId } from "./reducers/workspaceSlice"
import { fetchArticleWorkspaces } from "./reducers/recentlyReviewedSlice"
import PayNow from "./pages/registration/PayNow"
import GenerateInvoice from "./pages/registration/PayInvoice"
import useDragContainer from "./hooks/useDragContainer"
import {InvitedRoute} from "./components/InvitedRoute"

function App() {
	const user = useSelector(selectUser)
	const workspaceId = useSelector(selectWorkspaceId)
	const dispatch = useDispatch()
	const location = useLocation()
	const [error, setError] = useState(null)
	const queryParams = new URLSearchParams(location.search)
	const pricing = queryParams.get("pricing")
	const keyId = queryParams.get("key")
	const {
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		user: authUser,
	} = useAuth0()

	// Call the useDragContainer hook
	useDragContainer()

	useEffect(() => {
		dispatch(setSessionTimestamp())
		if(process.env.REACT_APP_REDUX_LOGS === "on") {
			document.title = "[BETA] Refzoom"
		}
	}, [])

	useEffect(() => {
		const setAuthorization = async () => {
			if (!isLoading && isAuthenticated) {
				console.warn("SETTING AUTH")
				const token = await getAccessTokenSilently()
				axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
				await dispatch(setUserAsync(authUser))
			}
		}

		setAuthorization().catch((err) => {
			setError(err)
			console.error(err.response.data)
		})
	}, [isLoading, isAuthenticated, getAccessTokenSilently, dispatch])
		
	useEffect(() => {
		dispatch(fetchArticleWorkspaces())
	}, [workspaceId])
	
	if (error) {
		return <ErrorPage />
	}

	if (isLoading) {
		return <Loading />
	}

	if (user && !user.registered && location.pathname !== "/register") {
		let uriString = ""
		if(pricing) {
			uriString += `?pricing=${pricing}`
		}
		if(keyId) {
			uriString += `&key=${keyId}`
		}

		return <Navigate to={`/register${uriString}`} />
	}

	if (user && user.registered && !user.isSubscribing && location.pathname === "/prices") {
		return <Navigate to={"/dashboard"} />
	}

	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path={"/invite"} element={<InvitedRoute />} />
			<Route
				path="/dashboard"
				element={<ProtectedRoute component={Dashboard} />}
			/>
			<Route
				path="/register"
				element={<ProtectedRoute component={RegisterForm} />}
			/>
			<Route path="/prices" element={<ProtectedRoute component={PricesForm} />} />
			<Route
				path="/subscribe"
				element={<ProtectedRoute component={PickPayment} />}
			/>
			<Route
				path={"/subscribe/pay-now"}
				element={<ProtectedRoute component={PayNow} /> }
			/>
			<Route
				path={"/subscribe/generate-invoice"}
				element={<ProtectedRoute component={GenerateInvoice}/>}
			/>
			<Route
				path="/account-settings"
				element={<ProtectedRoute component={AccountSettings} />}
			/>
			<Route
				path="/account-settings/subscription/licenses"
				element={<ProtectedRoute component={ManageLicenses} />}
			/>
			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}

export default App
