import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import isURL from "validator/lib/isURL"
import axios from "axios"
import { fetchUniqueCounts, fetchUniqueCountsForEachPath } from "./workspaceNoteSlice"
import { setWorkingAttachmentRelation } from "./workingArticleNoteRelationSlice"
import { attachArticlesToNote } from "./breadcrumbSlice"

/**
 * Redux slice for managing note attachments, including posting, editing,
 * fetching, and deleting attachments. It also handles attachment modal states,
 * validates attachment URLs, and manages associations between notes and attachments.
 * Supports both links and file attachments.
 *
 * @typedef {Object} InitialState
 * @property {Object.<string, Attachment>} noteAttachmentMap - Maps note IDs to their attachments (links and files).
 * @property {?string} selectedNote - ID of the currently selected note or null. Used in the modal only.
 * @property {boolean} isModalOpen - Indicates if the attachment modal is open. Used in the modal only.
 * @property {boolean} isSubmitting - Indicates if an attachment operation is in progress. Used in the modal only.
 * @property {?string} type - Type of attachment being managed (URL or file).
 * @property {?string} error - Error message from attachment operations.
 * @property {Object} noteAttachmentPreview - Preview of the attachment being managed. Used in the modal only.
 * @property {boolean} isUrlValid - Indicates if the URL of the previewed attachment is valid. Used in the modal only.
 * @property {?string} actionType - The action being performed (add, edit).
 * @property {Object.<string, Array>} breadcrumbs - Breadcrumb paths for attachments.
 * @property {Object.<string, Object>} relations - Relationships between attachments and notes.
 */

const defaultAttachmentObject = {
	url:"", 
	title:"", 
	description: "", 
	type: null
}
export const NOTE_ATTACHMENT_TYPES = {
	url: "url",
	file: "file"
}

export const postNoteAttachment = createAsyncThunk(
	"noteAttachmentSlice/postNoteAttachment",
	async ({attachment, note}, {getState, rejectWithValue, dispatch}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		const userId = state.user?.value?.id
		const noteId = note.id

		if (!workspaceId || !userId) {
			return rejectWithValue("Invalid WorkspaceId or UserId")
		}
	
		try {
			const url = `/api/workspace/${workspaceId}/user/${userId}/note/${noteId}/attachment`
			const {data} = await axios.post(url, attachment)
			dispatch(fetchUniqueCounts(note))
			return data
		} catch(error) {
			return rejectWithValue(error.response?.data?.message || error.message)
		}
	}
)

export const editNoteAttachment = createAsyncThunk(
	"noteAttachmentSlice/editNoteAttachment",
	async ({attachment}, {getState, rejectWithValue}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		const userId = state.user?.value?.id
		const noteId = attachment.noteId
		if (!workspaceId || !userId) {
			return rejectWithValue("Invalid WorkspaceId or UserId")
		}
		try {
			const url = `/api/workspace/${workspaceId}/user/${userId}/note/${noteId}/attachment/${attachment.id}`
			const {data} = await axios.put(url, attachment)
			return data
		} catch(error) {
			return rejectWithValue(error.response?.data?.message || error.message)
		}
	}
)

export const fetchNoteAttachments = createAsyncThunk(
	"noteAttachmentSlice/fetchNoteAttachments",
	async ({note}, {getState, rejectWithValue}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		const userId = state.user?.value?.id
		const noteId = note.id

		if (!workspaceId || !userId) {
			return rejectWithValue("Invalid WorkspaceId or UserId")
		}

		try {
			const url = `/api/workspace/${workspaceId}/user/${userId}/note/${noteId}/attachment`
			const {data} = await axios.get(url)
			return {attachments: data, noteId}
		} catch(error) {
			return rejectWithValue(error.response?.data?.message || error.message)
		}
	}
)


export const fetchSingleNoteAttachments = createAsyncThunk(
	"noteAttachmentSlice/fetchSingleNoteAttachments",
	async ({note}, {getState, rejectWithValue}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		const userId = state.user?.value?.id
		const noteId = note.id

		if (!workspaceId || !userId) {
			return rejectWithValue("Invalid WorkspaceId or UserId")
		}

		try {
			const url = `/api/workspace/${workspaceId}/user/${userId}/note/${noteId}/singleNoteAttachments`
			const {data} = await axios.get(url)
			return {attachments: data, noteId}
		} catch(error) {
			return rejectWithValue(error.response?.data?.message || error.message)
		}
	}
)

export const fetchNoteAttachmentBreadcrumbs = createAsyncThunk(
	"noteAttachmentSlice/fetchNoteAttachmentBreadcrumbs",
	async ({attachment, force = true}, {getState, rejectWithValue}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		const userId = state.user?.value?.id
		const noteId = attachment.noteId

		if (!workspaceId || !userId) {
			return rejectWithValue("Invalid WorkspaceId or UserId")
		}
		try {
			const breadcrumbs = state.noteAttachmentsSlice.breadcrumbs
			let breadcrumb = breadcrumbs[attachment.id]
			let attachmentNoteRelations = state.noteAttachmentsSlice.relations[attachment.id]
			if (!breadcrumb || force) {
				const url = `/api/workspace/${workspaceId}/user/${userId}/note/${noteId}/attachment/${attachment.id}/breadcrumb`
				const {data} = await axios.get(url)
				const {relations, superPaths} = data
				breadcrumb = superPaths
				attachmentNoteRelations = relations
			}
			return {breadcrumb, attachmentId: attachment.id, relations: attachmentNoteRelations}
		} catch(error) {
			return rejectWithValue(error.response?.data?.message || error.message)
		}
	}
)

export const deleteNoteAttachment = createAsyncThunk(
	"noteAttachmentSlice/deleteNoteAttachment",
	async ({attachment}, {getState, rejectWithValue, dispatch}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		const userId = state.user?.value?.id
		const noteId = attachment.noteId
		if (!workspaceId || !userId) {
			return rejectWithValue("Invalid WorkspaceId or UserId")
		}
	
		try {
			const url = `/api/workspace/${workspaceId}/user/${userId}/note/${noteId}/attachment/${attachment.id}`
			const {data} = await axios.delete(url)

			dispatch(fetchUniqueCountsForEachPath(data))
			return {deletedAttachment: attachment }
		} catch(error) {
			return rejectWithValue(error.response?.data?.message || error.message)
		}
	}
)

export const attachNoteToAttachment = createAsyncThunk(
	"noteAttachmentSlice/attachNoteToAttachment",
	async ({attachment, note}, {getState, rejectWithValue, dispatch}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		const userId = state.user?.value?.id
		const noteId = note.id
		const attachmentId = attachment.id

		if (!workspaceId || !userId || !noteId || !attachmentId) {
			return rejectWithValue("Invalid WorkspaceId or UserId")
		}

		try {
			// @httpPost("/note/:noteId/attachment/:attachmentId/noteAttachment", requireWrite)
			const url = `/api/workspace/${workspaceId}/user/${userId}/note/${noteId}/attachment/${attachmentId}/noteAttachment`
			await axios.post(url)
			dispatch(fetchUniqueCounts(note))
			dispatch(fetchNoteAttachmentBreadcrumbs({attachment}))
			dispatch(setWorkingAttachmentRelation({attachmentId: attachment.id}))
			return attachment
		} catch (error) {
			return rejectWithValue(error.response?.data || error.message)
		}
	
	}
)

export const detachNoteFromAttachment = createAsyncThunk(
	"noteAttachmentSlice/detachNoteFromAttachment",
	async ({attachment, note}, {getState, rejectWithValue, dispatch}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		const userId = state.user?.value?.id
		const noteId = note.id
		const attachmentId = attachment.id

		if (!workspaceId || !userId || !noteId || !attachmentId) {
			return rejectWithValue("Invalid WorkspaceId or UserId")
		}

		try {
			// @httpDelete("/note/:noteId/attachment/:attachmentId/noteAttachment", requireWrite)
			const url = `/api/workspace/${workspaceId}/user/${userId}/note/${noteId}/attachment/${attachmentId}/noteAttachment`
			await axios.delete(url)
			dispatch(fetchUniqueCounts(note))
			dispatch(fetchNoteAttachmentBreadcrumbs({attachment, force: true}))
			dispatch(setWorkingAttachmentRelation({attachmentId: attachment.id}))
			return {note, attachment}
		} catch (error) {
			return rejectWithValue(error.response?.data || error.message)
		}

	}
)

export const noteAttachmentsSlice = createSlice({
	name: "noteAttachmentsSlice",
	initialState: {
		noteAttachmentMap: {},
		selectedNote: null,
		isModalOpen: false,
		isSubmitting: false,
		type: null,
		error: null,
		noteAttachmentPreview: defaultAttachmentObject,
		isUrlValid: true,
		actionType: null,
		breadcrumbs: {},
		relations: {}
	},
	reducers: {
		initializeLinkAttachmentModal: (state, action) => {
			const { note, actionType, attachment } = action.payload
			state.selectedNote = note
			state.isModalOpen = true
			state.actionType = actionType
			state.type = NOTE_ATTACHMENT_TYPES.url
			const initAttachment = {...defaultAttachmentObject, type: NOTE_ATTACHMENT_TYPES.url}
			if (attachment) {
				state.selectedNote = attachment.note
				initAttachment.url = attachment.url
				initAttachment.title = attachment.title
				initAttachment.description = attachment.description
				initAttachment.type = attachment.attachmentType
				initAttachment.id = attachment.id
				initAttachment.noteId = attachment.noteId
				state.noteAttachmentPreview = initAttachment
			} else {
				state.noteAttachmentPreview = initAttachment
			}
		},
		closeAttachmentModal: (state) => {
			noteAttachmentsSlice.caseReducers.resetState(state)
		},
		updateNoteAttachmentPreview: (state, action) => {
			state.noteAttachmentPreview = action.payload
			state.isUrlValid = isURL(action?.payload?.url, { require_protocol: false })
		},
		resetState: (state) => {
			state.selectedNote = null
			state.isModalOpen = false
			state.isSubmitting = false
			state.type = null
			state.error = null
			state.isUrlValid = true
			state.noteAttachmentPreview = defaultAttachmentObject
			state.actionType = null
		},
		clearNoteAttachmentError: (state) => {
			state.error = null
		},
		addAttachment: (state, action) => {
			const attachment = action.payload

			// Ensure the noteId array is initialized
			if (!state.noteAttachmentMap[attachment.noteId]) {
				state.noteAttachmentMap[attachment.noteId] = []
			}
		
			// Find the index of the existing attachment, if it exists
			const existingIndex = state.noteAttachmentMap[attachment.noteId].findIndex(att => att.id === attachment.id)
		
			if (existingIndex !== -1) {
				// Replace the existing attachment
				state.noteAttachmentMap[attachment.noteId][existingIndex] = attachment
			} else {
				// Add the new attachment
				state.noteAttachmentMap[attachment.noteId].push(attachment)
			}
			noteAttachmentsSlice.caseReducers.resetState(state)
		},
		updatedAttachment: (state, action) => {
			const updatedAttachment = action.payload				
			// Iterate over each noteId in the noteAttachmentMap
			Object.keys(state.noteAttachmentMap).forEach(noteId => {
				// Find the index of the attachment that needs to be updated in each noteId
				const index = state.noteAttachmentMap[noteId].findIndex(attachment => attachment.id === updatedAttachment.id)
		
				// Check if the attachment is found in the current noteId
				if (index !== -1) {
					// If found, replace it with the updated one
					state.noteAttachmentMap[noteId][index] = updatedAttachment
				}
			})
			// Additionally, ensure the updated attachment is added to its primary noteId if not already present
			if (!state.noteAttachmentMap[updatedAttachment.noteId]?.some(attachment => attachment.id === updatedAttachment.id)) {
				if (!state.noteAttachmentMap[updatedAttachment.noteId]) {
					state.noteAttachmentMap[updatedAttachment.noteId] = []
				}
				state.noteAttachmentMap[updatedAttachment.noteId].push(updatedAttachment)
			}
			noteAttachmentsSlice.caseReducers.resetState(state)
		},
		removeAttachment: (state, action) => {
			const {deletedAttachment} = action.payload
			// Iterate over each key in noteAttachmentMap
			Object.keys(state.noteAttachmentMap).forEach(noteId => {
				// Filter out the deleted attachment for each noteId
				state.noteAttachmentMap[noteId] = state.noteAttachmentMap[noteId].filter(attachment => attachment.id !== deletedAttachment.id)
			})
			noteAttachmentsSlice.caseReducers.resetState(state)
		},
		clearAttachmentsFromTab: (state, action) => {
			state.noteAttachmentMap[action.payload] = []
		},
		detachAttachment: (state, action) => {
			const {note, attachment} = action.payload
			state.noteAttachmentMap[note.id].filter(attachmentObject => attachmentObject.id !== attachment.id)
			noteAttachmentsSlice.caseReducers.resetState(state)
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(postNoteAttachment.fulfilled, 	noteAttachmentsSlice.caseReducers.addAttachment)
			.addCase(fetchNoteAttachments.fulfilled, (state, action) => {
				const  {attachments, noteId} = action.payload
				state.noteAttachmentMap[noteId] = attachments
			})
			.addCase(fetchSingleNoteAttachments.fulfilled, (state, action) => {
				const  {attachments, noteId} = action.payload
				state.noteAttachmentMap[noteId] = attachments
			})
			.addCase(deleteNoteAttachment.fulfilled, noteAttachmentsSlice.caseReducers.removeAttachment)
			.addCase(editNoteAttachment.fulfilled, noteAttachmentsSlice.caseReducers.updatedAttachment)
			.addCase(editNoteAttachment.rejected, (state) => {
				state.error =  "Failed to edit attachment"
			})
			.addCase(fetchNoteAttachmentBreadcrumbs.fulfilled, (state, action) => {
				const { breadcrumb, attachmentId, relations } = action.payload 
				state.breadcrumbs[attachmentId] = breadcrumb
				state.relations[attachmentId] = relations
			})
			.addCase(attachNoteToAttachment.fulfilled, noteAttachmentsSlice.caseReducers.addAttachment)
			.addCase(attachNoteToAttachment.rejected, (state, action) => {
				state.error = action.payload
			})
			.addCase(attachArticlesToNote.rejected, (state, action) => {
				state.error = action.payload
			})
			.addCase(attachArticlesToNote.fulfilled, (state, action) => {
				if(action.payload.duplicateAttachmentCount) {
					state.error = `Attachment successful, ${action.payload.duplicateAttachmentCount} ${action.payload.duplicateAttachmentCount >= 2 ? "of the selected articles were" : "selected article was"} already attached to the target note.`
				}
			})
			.addCase(detachNoteFromAttachment.fulfilled, noteAttachmentsSlice.caseReducers.detachAttachment)
	}
})

export const { 
	initializeLinkAttachmentModal, 
	loseAttachmentModal, 
	updateNoteAttachmentPreview, 
	clearNoteAttachmentError, 
	addAttachment, 
	updatedAttachment, 
	removeAttachment,
	closeAttachmentModal,
	detachAttachment,
	attachAttachment,
	clearAttachmentsFromTab
} = noteAttachmentsSlice.actions

export const selectNoteAttachmentPreview = (state) => state.noteAttachmentsSlice.noteAttachmentPreview
export const selectNote = (state) => state.noteAttachmentsSlice.selectedNote
export const selectIsModalOpen = (state) => state.noteAttachmentsSlice.isModalOpen
export const selectIsLinkValid = (state) => state.noteAttachmentsSlice.isUrlValid
export const selectActionType = (state) => state.noteAttachmentsSlice.actionType
export const selectNoteAttachmentBreadcrumbs = (state) => state.noteAttachmentsSlice.breadcrumbs
export const selectNoteAttachmentError = (state) => state.noteAttachmentsSlice.error
export const selectAttachmentRelations = (state) => state.noteAttachmentsSlice.relations

export const selectIsNoteAttachmentPreviewValid = (state) => {
	const preview = state.noteAttachmentsSlice.noteAttachmentPreview
	// Ensure all necessary validation checks are replicated here
	return isURL(preview.url, { require_protocol: false }) &&
			preview.title !== "" &&
			preview.description !== ""
}
export const selectNoteAttachmentMap = (state) => state.noteAttachmentsSlice.noteAttachmentMap

export default noteAttachmentsSlice.reducer