import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { setWorkingArticleNoteRelationAsync } from "./workingArticleNoteRelationSlice"
import axios from "axios"
import { fetchUniqueCounts } from "./workspaceNoteSlice"

// breadcrumb thunk should load in data when a tab is open
export const fetchArticleBreadcrumbs = createAsyncThunk(
	"breadcrumb/fetchArticleBreadcrumbs",
	async ({article, note}, {getState, rejectWithValue, dispatch}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		if (!workspaceId) return rejectWithValue("Breadcrumbs failed to load. Invalid Workspace ID")
		try {
			const {data} = await axios.get(`/api/workspace/${workspaceId}/article/${article.id}/note`)
			dispatch(setWorkingArticleNoteRelationAsync(workspaceId, article.id))
			// fetch unique count if note is passed in deletion or attachment
			if (note) {
				dispatch(fetchUniqueCounts(note))
			}
			return {
				workspaceId,
				article,
				breadcrumbs: data
			}
		} catch (error) {
			return rejectWithValue(error?.response?.message || `Failed to load breadcrumbs for article ${article.id}`)
		}

	}
)

// NOTE: this is THE attachment thunk. Although articleDragSlice.setOnDrop gets invoked first, it does not make a request to the backend.
// even though this thunk is in the breadcrumb slice, this is the only way to trigger "NoteService.attachArticle" from the client.
export const attachArticlesToNote = createAsyncThunk(
	"breadcrumb/attachArticlesToNote",
	async ({note, articles, isUnassigned}, {getState, rejectWithValue, dispatch}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		if (!workspaceId) return rejectWithValue("Failed to attach article to note. Invalid Workspace ID")
		try {
			const { data: articleAttachmentDTO } = await axios.post(`/api/workspace/${workspaceId}/note/${note.id}/article`,
				{
					articles,
					isUnassigned,
					note
				}
			)
			const articleNoteRelationsList = articleAttachmentDTO.articleNote

			articleNoteRelationsList.forEach(({articleId}) => {
				// reload breadcrumbs if articleMap[articleId] !== null
				if (state.breadcrumbSlice.articleMap[articleId]) {
					dispatch(fetchArticleBreadcrumbs({article: {id: articleId}, note}))
				} else {
					// don't update crumb but update unique count
					dispatch(fetchUniqueCounts(note))
				}
			})
			
			return {articleNoteRelationsList, note, duplicateAttachmentCount: articleAttachmentDTO.duplicateAttachmentCount}
		} catch (error) {
			return rejectWithValue(error.response?.data || error.message)
		}
	}
)

export const deleteArticleNoteRelation = createAsyncThunk(
	"breadcrumb/deleteArticleNoteRelation",
	async ({note, articleId}, {getState, rejectWithValue, dispatch}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		if (!workspaceId) return rejectWithValue("Failed to remove note from article. Invalid Workspace ID")
		try {
			// this route returns the note path (originally consumed by refresh relay)
			await axios.delete(`/api/workspace/${workspaceId}/note/${note.id}/article/${articleId}`)
			// reload breadcrumbs if articleMap[articleId] !== null
			if (state.breadcrumbSlice.articleMap[articleId]) {
				dispatch(fetchArticleBreadcrumbs({article: {id: articleId}, note}))
			}
			return {
				note, 
				articleId
			}
		} catch (error) {
			return rejectWithValue(error?.response?.message || `Failed to remove note ${note.id} from article ${articleId}`)
		}
	}
)
export const breadcrumbSlice = createSlice({
	name: "breadcrumb",
	initialState: {
		articleMap: {},
		trackedBreadcrumb: null,
		error: null
	},
	extraReducers: (builder) => {
		builder.addCase(fetchArticleBreadcrumbs.fulfilled, (state, action) => {
			const {article, breadcrumbs} = action.payload
			// we are ensuring there is only every one breadcrumb present. 
			// without resetting the object, we could manage multiple breadcrumbs in memory which could 
			// be useful in the future
			state.trackedBreadcrumb = {
				articleId: article.id,
				breadcrumbTopLevelNoteIds: breadcrumbs.map(x => x.id)
			}
			state.articleMap[article.id] = breadcrumbs
		})
	}
})

export const selectArticleBreadcrumbs = (state) => state.breadcrumbSlice.articleMap

export default breadcrumbSlice.reducer