import {
	useState,
	useEffect,
} from "react"
import "../../../styles/Icons.css"
import {
	Box,
	Card,
	Collapse, Fade,
	List,
	ListItem,
} from "@mui/material"
import {
	ArrowDownward,
	ExpandMoreRounded
} from "@mui/icons-material"
//import AddNote from "./AddNote"
import { useDispatch, useSelector } from "react-redux"
import {
	selectTargetNote,
	setTargetNote,
} from "../../../reducers/targetNoteSlice"
import {clearNoteAction, NOTE_ACTIONS, selectNoteAction} from "../../../reducers/noteActionSlice"
import { selectWorkingArticleNoteRelation } from "../../../reducers/workingArticleNoteRelationSlice"
import { reorderNotes } from "../../../utils/noteUtilities"
//import { selectPresentationNotes } from "../../../reducers/notePresentationSlice"
import {ToggleChildrenIcon} from "./ToggleChildrenIcon"
import Note from "./Note"
import NoteArticleRelationsIcon from "./NoteArticleRelationsIcon"
import AddNote from "./AddNote"
import {selectNotesOrder} from "../../../reducers/noteOrderSlice"

const NOTE_ARTICLE_RELATIONSHIP = {
	NONE: 0,
	RELATED: 1,
	ATTACHED: 2
}


const NoteTreeV2 = ({ note, workspaceId, show, hideAll, showingContext, index }) => {
	const dispatch = useDispatch()
	const children = Object.values(note?.noteChildren || []).sort((a, b) => a.order - b.order)
	const targetNote = useSelector(selectTargetNote)
	const articleRelations = useSelector(selectWorkingArticleNoteRelation)
	const noteAction = useSelector(selectNoteAction)
	// const notePositions = useSelector(selectPresentationNotes)
	const childrenOrder = useSelector(state => selectNotesOrder(state, note?.id))
	const [articleRelationship, setArticleRelationship] = useState(0)

	useEffect(() => {
		const isAttached = articleRelations?.attached.indexOf(note?.id) !== -1
		const isRelated = articleRelations?.related.indexOf(note?.id) !== -1

		if (isAttached) {
			setArticleRelationship(NOTE_ARTICLE_RELATIONSHIP.ATTACHED)
		} else if (isRelated) {
			setArticleRelationship(NOTE_ARTICLE_RELATIONSHIP.RELATED)
		} else {
			setArticleRelationship(NOTE_ARTICLE_RELATIONSHIP.NONE)
		}

	}, [articleRelations, note?.id])


	const selectNote = (e) => {
		e.stopPropagation()
		if(noteAction === NOTE_ACTIONS.cancelNote){
			dispatch(clearNoteAction())
		} else {
			hideAll()
			dispatch(setTargetNote(note))
		}
	}

	const handleMenuIconMouseOver = (e) => {
		if (showingContext) {
			return
		}
		const targetBoundingRect = e.target.getBoundingClientRect()
		show({
			event: e,
			props: {
				note: note,
				parent: note?.parentId,
				workspaceId: workspaceId,
				fromHover: true,
				hideAll: hideAll,
			},
			position: {
				x: targetBoundingRect.x - 75,
				y: targetBoundingRect.y +(targetBoundingRect.height / 2),
			},
		})
	}

	const handleMenuIconMouseLeave = (e) => {
		// we use the contexify library for our context menu
		// contexify components include either the class ".contexify" or start with ".contexify__"
		// so, below, we're looking for contexify classes in both forms
		// a possible optimization here would be to short-circuit if relatedTargetIsContexify evaluates to true
		let relatedTargetClassList = e.relatedTarget.classList
		let cursorMovingToMenu = false

		for(let className of relatedTargetClassList) {
			if(className.includes("contexify")){
				cursorMovingToMenu = true
				break // if we find one, no need to eval the rest
			}
		}

		if(!cursorMovingToMenu) {
			hideAll()
		}
	}

	return (
		<List
			dense
			variant={"notetree"}
			toplevel={note?.parentId === null ? "true" : "false" }
		>
			{targetNote.id === note?.id && noteAction.action === NOTE_ACTIONS.addRoot && (
				<AddNote note={{ workspaceId: workspaceId, id: targetNote.id, path: targetNote.path }} />
			)}
			<ListItem
				id={`note-${note?.id}`}
				variant={"notetree"}
				toplevel={note?.parentId === null ? "true" : "false" }
			>
				<Box
					sx={{
						display: "flex",
						position: "relative",
						paddingRight: "15px",
						paddingY: "0.5px",
						alignItems: "top",
					}}
					onClick={selectNote}
				>
					<ToggleChildrenIcon
						note={note}
						childCount={note?.childCount}
						noteAction={noteAction.action}
					/>
					{
						targetNote.id === note?.id
						&& noteAction.action !== NOTE_ACTIONS.editNote
						&& noteAction.action !== NOTE_ACTIONS.addChild
						&& noteAction.action !== NOTE_ACTIONS.addSibling
						&& noteAction.action !== NOTE_ACTIONS.addRoot
						&& (
							<ExpandMoreRounded
								fontSize={"large"}
								opacity={0.8}
								onMouseEnter={handleMenuIconMouseOver}
								onMouseLeave={handleMenuIconMouseLeave}
								sx={{
									zIndex: 100,
									alignSelf: "start",
									height: "auto",
									color:"primary.contrastText",
									"&.MuiSvgIcon-root": {
										backgroundColor: "primary.main",
										borderRadius: "100%",
										position: "absolute",
										right: "20%",
										top: "50%",

									}
								}}
							/>
						)
					}
					<Note note={note} index={index} />
					<Fade
						in={articleRelationship === NOTE_ARTICLE_RELATIONSHIP.RELATED && !note?.showChildren}
						appear unmountOnExit
					>
						<ArrowDownward
							sx={{
								position: "absolute",
								right: "-5px",
								top: "9px",
								color: "blue",
								fontSize: "20px",
							}}
						/>
					</Fade>
					<Fade
						in={articleRelationship === NOTE_ARTICLE_RELATIONSHIP.ATTACHED}
						appear unmountOnExit
					>
						<span> {/* prevents known MUI issue resulting in a crash when inner component is empty. Yes, it has to be here and not in the child component*/}
							<NoteArticleRelationsIcon note={note} />
						</span>
					</Fade>
				</Box>
				<Collapse in={note?.showChildren} timeout={400} unmountOnExit>
					{note?.loadingChildren &&
						[...Array(note?.childCount || 0).keys()].map((e, i) => {
							return (
								<List
									key={i}
									sx={{
										paddingTop: "2px",
									}}
									variant={"notetree"}
								>
									<ListItem
										variant={"notetree"}
									>
										<Card elevation={0} sx={{ height: "30px" }}>
											Loading...
										</Card>
									</ListItem>
								</List>
							)
						})}
					{reorderNotes(children, childrenOrder).map((child, i) => (
						<NoteTreeV2
							key={child?.id}
							index={i}
							note={child}
							hideAll={hideAll}
							show={show}
							workspaceId={workspaceId}
						/>
					))}
				</Collapse>
			</ListItem>
		</List>
	)
}

export default NoteTreeV2